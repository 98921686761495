import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import Map from "./components/Map/Map";
import "./styles/css/Apps.scss";
import Search from "./components/Search/Search.jsx";
import CourtDetail from "./components/CourtDetail/CourtDetail";
//import LinkedButtons from "./components/Buttons/LinkedButtons";
import Div100vh from "react-div-100vh";
import {useParams} from "react-router-dom/cjs/react-router-dom";

const axios = require("axios");

//initializes app
const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <WorldMap/>
                </Route>
                <Route exact path="/courts/:id">
                    <WorldMap/>
                </Route>
            </Switch>
        </Router>
    );
};

function WorldMap() {
    let {id} = useParams();
    if (id) id = parseInt(id);
    const mapRef = useRef();
    const [openForm] = useState(true);
    const [zoom, setZoom] = useState(0);
    const [autocomplete, setAutocomplete] = useState(null);

    //-------------------------------------------//
    //   Autocomplete is map's autocomplete      //
    //   options available on search             //
    //-------------------------------------------//

    // function to set autocomplete on onLoad
    const onLoad = (autoC) => {
        setAutocomplete(autoC);
    };

    // function that updates map on search entry
    const onPlaceChanged = () => {
        // sets map location to autocomplete selection location
        const lat = autocomplete.getPlace().geometry.location.lat();
        const lng = autocomplete.getPlace().geometry.location.lng();

        // on autocomplete options click
        // if clicked option is just a country, then zoom map to 6
        if (autocomplete.getPlace().types[0] === "country") {
            mapRef?.current.setZoom(6);
        }
        // if clicked option is a small locataion, city or route, then zoom map to 15
        else if (
            autocomplete.getPlace().types[0] === "route" ||
            autocomplete.getPlace().types[0] === "sublocality_level_1" ||
            autocomplete.getPlace().types[0] === "sublocality"
        ) {
            mapRef?.current.setZoom(15);
        }

        // if clicked option is a business or establishment, then zoom map to 17
        else if (
            autocomplete.getPlace().types[0] === "administrative_area_level_" ||
            autocomplete.getPlace().types[0] === "stadium" ||
            autocomplete.getPlace().types[0] === "restaurant" ||
            autocomplete.getPlace().types[0] === "food" ||
            autocomplete.getPlace().types[0] === "establishment"
        ) {
            mapRef?.current.setZoom(17);
        }

            // TODO: Check if this is actually necessary or if we can just move it to small location if sentence
        // if clicked option is ??????, then zoom map to 14
        else if (
            autocomplete.getPlace().types[0] === "point_of_interest" ||
            autocomplete.getPlace().types[0] === "locality"
        ) {
            mapRef?.current.setZoom(14);
        } else {
            mapRef?.current.setZoom(11);
        }

        // call function to set coordinates
        setCoordinates({lat, lng});
    };

    // sets court to show court details, maybe?
    const [selectedCourt, setSelectedCourt] = useState({});

    // sets button to close form, maybe?
    const [closeBtn, setCloseBtn] = useState(false);

    // who's child?
    const [childClicked, setChildClicked] = useState(null);

    // sets courts coordinates
    const [coordinates, setCoordinates] = useState({});

    // bounds?
    const [bounds, setBounds] = useState(null);

    // sets data?
    const [data, setData] = useState([]);

    // setting initial coordinates
    useEffect(() => {
        // sets initial coordinates to 38.710..., -9.136...
        //TODO: set initial coordinates to user's coordinates
        setCoordinates({
            lat: 38.710228877706705,
            lng: -9.136444173296772
        });
    }, []);


    // gets data from json file on server
    useEffect(() => {

        async function fetchData() {
            const response = await axios.get(
                `${process.env.REACT_APP_COURTS_API_URL}/courts?limit=4000`
            );
            setData(response.data.courts);
            console.log('fetchData() success');
            return response.data.courts;
        }

        fetchData()
            .then((res) => {
                if (id) {
                    console.log('court/:id =', id);
                    const courtHal = res.find((c) => c.court.id === id);
                    if (courtHal) {
                        console.log('court found! name =', courtHal.court.name);
                        setCloseBtn(true);
                        setSelectedCourt(courtHal.court);
                    }else{
                        console.log('court not found!');
                    }
                }
            });


    }, [id]);


    return (
        <Div100vh style={{backgroundColor: "black"}}>
            {/** creates a div that initializes app */}
            <div className="app">
                {/** sets Search component with given data */}
                {openForm && (
                    <Search
                        onPlaceChanged={onPlaceChanged}
                        onLoad={onLoad}
                        styleclass={"search"}
                    />
                )}

                <Map
                    mapRef={mapRef}
                    setCoordinates={setCoordinates}
                    data={data}
                    coordinates={coordinates}
                    setBounds={setBounds}
                    setChildClicked={setChildClicked}
                    childClicked={childClicked}
                    setSelectedCourt={setSelectedCourt}
                    setCloseBtn={setCloseBtn}
                    bounds={bounds}
                    zoom={zoom}
                    setZoom={setZoom}
                />

                {closeBtn && (
                    <CourtDetail
                        court={selectedCourt}
                        setCloseBtn={setCloseBtn}
                    />
                )}

            </div>
        </Div100vh>
    );
}


export default App;
