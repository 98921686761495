import GoogleMapReact from "google-map-react";
import { useState } from "react";
import useSupercluster from "use-supercluster";
import "./_map.scss";
import mapStyles from "./styles";
import {useHistory} from "react-router-dom";

const Map = ({
	setCoordinates,
	coordinates,
	data,
	setChildClicked,
	setSelectedCourt,
	setCloseBtn,
	zoom,
	setZoom,
	mapRef
	//bounds,
}) => {
	//const [isAllCourts, setIsAllCourts] = useState(true);

	/*
    const changeToggleValue = () => {
    	setIsAllCourts(!isAllCourts);
	};
    */

	const history = useHistory();

	// const mapRef = useRef();
	const [bounds, setBounds] = useState(null);
	const Marker = ({ children }) => children;

	let points = data.map((value) => ({
		type: "Feature",
		properties: {
			cluster: false,
			courtId: value.court.id,
			category: "court",
			courtinfo: value.court
		},
		geometry: {
			type: "Point",
			coordinates: [value.court.lng, value.court.lat]
		}
	}));

	//on localhost it should be "true\n"... go figure :|

	points = points?.filter(
		(point) => point.properties.courtinfo.is_unvalidated !== true
	);

	/*/ check value of toggle switch. If its false show all courts, if true show only the ones that either have whatsapp group, calendar or join games functionality
	points = !isAllCourts
		? points?.filter(
				(point) =>
					point.properties.courtinfo.has_whatsapp === "true" ||
					point.properties.courtinfo.has_calendly === "true" ||
					point.properties.courtinfo.has_teamup === "true"
		  )
		: points;
    */
	const { clusters, supercluster } = useSupercluster({
		points,
		bounds,
		zoom,
		options: { radius: 100, maxZoom: 20, minPoints: 1 }
	});

	return (
		<div className="mapContainer">
			{
				//<ToggleSwitch changeToggleValue={changeToggleValue}></ToggleSwitch>
			}

			<GoogleMapReact
				bootstrapURLKeys={{
					key: process.env.REACT_APP_GOOGLE_MAPS_API
				}}
				defaultCenter={{
					lat: 38.710228877706705,
					lng: -9.136444173296772
				}}
				center={coordinates}
				defaultZoom={1}
				options={{
					mapId: "5dd94f09d26290a3",
					streetViewControl: true,
					restriction: {
						latLngBounds: {
							north: 85,
							south: -85,
							west: -179.9999,
							east: 179.9999
						},
						strictBounds: true
					},
					gestureHandling: "greedy",
					fullscreenControl: false
				}}
				defaultOptions={{ styles: mapStyles }}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map }) => {
					mapRef.current = map;
					map.setMapTypeId("terrain");
				}}
				onChange={({ zoom, bounds, center }) => {
					setZoom(zoom);

					setCoordinates({
						lat: center.lat,
						lng: center.lng
					});
					setBounds([
						bounds.nw.lng,
						bounds.se.lat,
						bounds.se.lng,
						bounds.nw.lat
					]);
					/*console.log("bounds se lat: " + bounds.se.lat);
					console.log("bounds nw lat: " + bounds.nw.lat);
					console.log("lat:" + center.lat);
					console.log("bounds nw lng: " + bounds.nw.lng);
					console.log("bounds se lng: " + bounds.se.lng);
					console.log("lng:" + center.lng);
					console.log("zoom:" + zoom);*/
				}}
				onChildClick={(child) => {
					setChildClicked(child);
					// console.log({ childClicked });
				}}
				// margin={[50, 50, 50, 50]}
			>
				{clusters?.map((cluster) => {
					//
					const [longitude, latitude] = cluster.geometry.coordinates;
					const { cluster: isCluster, point_count: pointCount } =
						cluster.properties;

					//console.log(cluster?.properties?.courtinfo?.formcourt);
					if (isCluster) {
						return (
							<Marker
								key={`cluster-${cluster.clusterId}`}
								lat={latitude}
								lng={longitude}
							>
								<div
									className="cluster-marker"
									style={{
										width: `${
											10 +
											(pointCount / points.length) * 20
										}px`,
										height: `${
											10 +
											(pointCount / points.length) * 20
										}px`
									}}
									onClick={() => {
										const expansionZoom = Math.min(
											supercluster.getClusterExpansionZoom(
												cluster.id
											),
											20
										);
										mapRef.current.setZoom(expansionZoom);
										mapRef.current.panTo({
											lat: latitude,
											lng: longitude
										});
									}}
								>
									{pointCount}
								</div>
							</Marker>
						);
					}

					return (
						<Marker
							key={`cluster-${cluster.clusterId}`}
							lat={latitude}
							lng={longitude}
						>
							<div
								onClick={() => {
									history.push(`/courts/${cluster.properties.courtinfo.id}`);
								}}
								className="mapicon"
							>
								{cluster?.properties.courtinfo.is_hoopers ===
								true ? (
									<img
										src="/assets/courts/by_hoopers.svg"
										alt=""
									/>
								) : (
									<img
										src="/assets/courts/Courtico.svg"
										alt=""
									/>
								)}
							</div>
						</Marker>
					);
				})}
			</GoogleMapReact>
		</div>
	);
};

export default Map;
